<template>
  <div>
    <img :src="imageURI1" style="width: 200px" ref="image1" />
    <img :src="imageURI2" style="width: 200px" ref="image2" />
    result : {{ result }}
    <br />
  </div>
</template>

<script>
import * as faceapi from 'face-api.js'

export default {
  data() {
    return {
      imageURI1: '/images/test-face-api/taylor_swift_1.jpg',
      imageURI2: '/images/test-face-api/taylor_swift_2.jpg',
      distance: 0,
      descriptors: { desc1: null, desc2: null },
      result: '',
    }
  },
  methods: {
    async init(which, uri) {
      const input = await faceapi.fetchImage(uri)
      this.descriptors[`desc${which}`] = await faceapi.computeFaceDescriptor(
        input
      )
    },
    updateResult() {
      const threshold = 0.6

      this.distance = faceapi.utils.round(
        faceapi.euclideanDistance(
          this.descriptors.desc1,
          this.descriptors.desc2
        )
      )
      this.result = this.distance
      if (this.distance > threshold) {
        this.result += ' (no match)'
      }
    },
  },
  async created() {
    await faceapi.loadFaceRecognitionModel('/models')
    await this.init(1, this.imageURI1)
    await this.init(2, this.imageURI2)
    await this.updateResult()
  },
}
</script>

<style scoped></style>
